import { BarChart3, LineChart, Presentation, Layout, Target } from 'lucide-react'
import Image from 'next/image'
import styles from './index.module.less'
import { imgUrl } from '@/shared/utils'
import { commonImgPath } from '@/shared/app-common'
import { useMemo, useState } from 'react'


export default function HomeFeatureUI({ onClick }: { onClick?: () => void }) {

  const listData = useMemo(() => {
    return [
      {
        title: 'Smart Monitoring for Price Changes >',
        desc: 'Monitor competitor pricing and promotions, adjust your pricing strategy in real-time, and boost your market competitiveness',
        icon: '/images/home/feature-icon-1.webp'
      },
      {
        title: 'Stay Ahead with Policy Changes >',
        desc: 'Keep track of regulatory changes, ensure compliance, reduce legal risks, and maintain smooth business operations',
        icon: '/images/home/feature-icon-2.webp'
      },
      {
        title: 'Track Competitor Web Design Changes >',
        desc: 'Monitor competitor web design to understand audience preferences and improve your strategy',
        icon: '/images/home/feature-icon-3.webp'
      },
      {
        title: 'Optimize Ad & Marketing Strategies >',
        desc: 'Analyze competitor ads and promotional tactics to optimize your own strategy and increase potential customer conversions',
        icon: '/images/home/feature-icon-4.webp'
      },
    ]
  }, [])

  return (
    <div className={styles.container}>

      <div className={styles.topContainer}>
        <h2>{`Unlock the Power of Website Monitoring for Any Scenario`}</h2>
        <span>{`Explore How InstantKnow Tracks Website Changes for Every Need`}</span>
      </div>

      <div className={styles.grid}>
        <div className={styles.leftColumn} onClick={onClick}>
          <div className={styles.icon}>
            <Image src={'/images/home/feature-icon-0.webp'} alt='' width={40} height={40} />
          </div>
          <h2 className={styles.title}>{`Track Competitor Key Updates >`}</h2>
          <p className={styles.description}>{`Track competitor changes in real-time, analyze market trends, and adjust your strategy to stay ahead of the competition`}</p>

          <div className={styles.imageContainer}>
            <Image
              src={imgUrl((commonImgPath + '/home-feature-icon.webp'), 'big')}
              alt="Dashboard preview"
              width={400}
              height={200}
              className={styles.image}
            />
          </div>
        </div>
        <div className={styles.rightColumn}>
          {listData.map((feature, index) => (
            <div key={index} className={styles.featureCard} onClick={onClick}>
              <div className={styles.iconTitle}>
                <Image src={feature?.icon} alt='' width={40} height={40} />
                <div className={styles.cardTitle}>{feature.title}</div>
              </div>
              <div className={styles.cardDescription}>
                <h3 className={styles.cardTitle}>{feature.title}</h3>
                <p className={styles.cardDescriptionText}>{feature.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

